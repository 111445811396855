import logo from '../assets/images/logo_simples.png';

const HeaderSubscribe = () => {
  return (
    <>
      <img 
        src={logo} 
        alt="Logo" 
        className="w-15 mb-5 md:transform md:transition-transform md:duration-300 md:hover:scale-110" 
        style={{ width: '80px' }}
      />
      
      <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Stock Tensor - newsletter</h2>
      
      <p className="mt-4 text-lg leading-8 text-gray-300">
        Receba as principais atualizações e insights do mundo das finanças diretamente na sua caixa de entrada, 
        todos os dias, de forma 100% gratuita. 
        Começe o dia com vantagem – perfeito se acompanhado 
        por uma boa xícara de café (sem açúcar) ☕
      </p>
    </>
  );
};

export default HeaderSubscribe;