import { useState } from 'react';
import Alert from '../components/Alert';
import HeaderSubscribe from '../components/HeaderSubscribe';
import InputSubscribe from '../components/InputSubscribe';
import FeaturesSubs from '../components/FeaturesSubs';
import ImageSubscribe from '../components/ImageSubscribe';
import config from '../config';

const MainWrapper = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  
  const message_erro = 'Desculpe! Houve algum problema no servidor 😢 Tente de novo mais tarde!';
  const message_sucess = 'Inscrição realizada com sucesso!';
  const message_invalid_email = 'Por favor, insira um e-mail válido!';

  // Função para verificar se o e-mail é válido
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex simples para validar e-mail
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();

    // Verificar se o e-mail é válido
    if (!isValidEmail(email)) {
      showAlert('error', message_invalid_email); // Mostrar mensagem de erro
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/api/s1/subscribe-newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setMessage(message_sucess);
        showAlert('success', message_sucess);
      } else {
        setMessage('Ocorreu um erro. Tente novamente.');
        showAlert('error', message_erro);
      }
    } catch (error) {
      setMessage('Ocorreu um erro. Tente novamente.');
      showAlert('error', message_erro);
    }
  };

  const showAlert = (type, message) => {
    setAlertVisible(true);
    setMessage(message);
    setTimeout(() => {
      setAlertVisible(false);
    }, 5000);
  };

  return (
    <div className="relative isolate overflow-hidden bg-gradient-to-br from-gray-900 via-blue-900 to-black py-16 sm:py-24 min-h-screen flex items-center justify-center">
      
      <Alert message={message} isVisible={alertVisible} type={message === message_erro || message_invalid_email ? 'error' : 'success'} />

      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <HeaderSubscribe />
            <InputSubscribe email={email} handleChange={handleChange} handleSubscribe={handleSubscribe} />
            {message && <p className="mt-4 text-lg text-white">{message}</p>}
            <FeaturesSubs />
          </div>
          <ImageSubscribe />
        </div>
      </div>
    </div>
  );
};

export default MainWrapper;