import iPhoneTela from '../assets/images/iPhone_pespec.png';

const ImageSubscribe = () => {
  return (
    <div className="flex flex-col items-center justify-center lg:flex hidden">
      <img 
        src={iPhoneTela} 
        alt="Phone Preview"
        className="w-15 mb-5 md:transform md:transition md:duration-300 md:ease-in-out md:hover:scale-105" 
        style={{ width: '300px' }}
      />
    </div>
  );
};

export default ImageSubscribe;