import background_comming from '../assets/images/background_commingS_mod.png';
import { EnvelopeIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline'
import Button_1 from '../components/Button_1';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo_complete_1.png';
import ProgressBar from '../components/ProgressBar';

export default function Home() {


    return (
      <>
        <main className="relative grid min-h-screen place-items-center bg-gradient-to-br from-gray-900 via-blue-900 to-black">
          {/* Imagem de fundo */}
          {/* <div className="relative isolate overflow-hidden bg-gradient-to-br from-gray-900 via-blue-900 to-black py-16 sm:py-24 min-h-screen flex items-center justify-center"></div> */}
          {/* <div className="absolute inset-0">
            <img
              src={background_comming}  // Substitua pelo caminho da sua imagem
              alt="Background"
              className="h-full w-full object-cover min-h-screen"
            />
            <div className="absolute inset-0 bg-gradient-to-r from-blue-700 to-black mix-blend-multiply opacity-100 backdrop-blur-sm "></div>
          </div> */}

          
  
          {/* Conteúdo central */}
          
            <div className="relative z-10 text-center m-10">
                {/* <img 
                    src={logo} 
                    alt="Logo" 
                    className="w-15 mb-15" 
                    style={{ width: '350px' }}

                /> */}
                <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                Em breve...
                </h1>
                <p className="mt-6 text-lg leading-7 text-gray-200">
                Estamos preparando algo incrível. Fique atento!

                </p>
                

                <div className="mt-10 w-full max-w-md mx-auto">
                    <p className="text-white mb-2">70%</p>
                    <ProgressBar progress={70} />
              </div>
              <p className="mt-6 text-lg leading-7 text-gray-200">
                Enquanto o café fica pronto ☕, você pode curtir grátis: 
                </p>


                <div className="flex justify-center items-center max-w-md m-15">
                <dl className="grid grid-cols-2 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2 mt-10">
                    <div className="flex flex-col items-start">
                    <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                        <EnvelopeIcon aria-hidden="true" className="h-6 w-6 text-white md:transform md:transition md:duration-200 md:hover:scale-110" />
                    </div>
                    <dt className="mt-4 font-semibold text-white">Nossa Newsletter!</dt>
                    <dd className="mt-2 leading-7 text-gray-400 text-left">
                    Fique por dentro do mercado em 5 minutos!

                    Notícias, comentários do StockBot, análises técnicas, e muito mais. 100% gratuito.
                    </dd>
                    <Button_1 text="Subscribe" to="/subscribe" external={false}/>
                    </div>
                    <div className="flex flex-col items-start">
                    <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                        <PaperAirplaneIcon aria-hidden="true" className="h-6 w-6 text-white md:transform md:transition md:duration-200 md:hover:scale-110" />
                    </div>
                    <dt className="mt-4 font-semibold text-white">Nosso canal no Telegram!</dt>
                    <dd className="mt-2 leading-7 text-gray-400 text-left">
                    Além das notícias mais quentes, receba insights exclusivos gerados 
                    por nossa inteligência artificial 🤖
                    </dd>
                    <Button_1 text="Telegram" to="https://t.me/stockTensor" external={true}/>
                    
                    </div>
                
                </dl>
                </div>
            </div>

          
        </main>
      </>
    );
  }