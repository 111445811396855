import { useNavigate } from 'react-router-dom';

export default function Button_1({ text, to, external}) {


  const navigate = useNavigate();

  const handleClick = () => {
    if (external) {
      // Se for um link externo, usa window.location.href
      window.location.href = to;
    } else {
      // Se for uma rota interna, usa navigate
      navigate(to);
    }
  };

    return (
      <button
        className="mt-4 flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
        onClick={handleClick}
      >
        {text}
      </button>
    );
  }