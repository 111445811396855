const Alert = ({ message, isVisible, type }) => {
    if (!isVisible) return null;
    
    return (
      <div className={`fixed top-0 left-0 w-full py-2 text-center ${type === 'error' ? 'bg-red-500' : 'bg-green-500'}`}>
        <p className="text-white">{message}</p>
      </div>
    );
  };
  
  export default Alert;