import { CalendarDaysIcon, CpuChipIcon } from '@heroicons/react/24/outline';

const FeaturesSubs = () => {
  return (
    <dl className="grid grid-cols-2 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2 mt-10">
      <div className="flex flex-col items-start">
        <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
          <CalendarDaysIcon aria-hidden="true" className="h-6 w-6 text-white md:transform md:transition md:duration-200 md:hover:scale-110" />
        </div>
        <dt className="mt-4 font-semibold text-white">Todos os dias</dt>
        <dd className="mt-2 leading-7 text-gray-400">
          Fique por dentro do mercado em 5 minutos! Notícias, comentários do StockBot, análises técnicas, e muito mais. 100% gratuito.
        </dd>
      </div>

      <div className="flex flex-col items-start">
        <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
          <CpuChipIcon aria-hidden="true" className="h-6 w-6 text-white md:transform md:transition md:duration-200 md:hover:scale-110" />
        </div>
        <dt className="mt-4 font-semibold text-white">Tecnologia</dt>
        <dd className="mt-2 leading-7 text-gray-400">
          Além das notícias mais quentes, receba insights exclusivos gerados por nossa inteligência artificial 🤖
        </dd>
      </div>
    </dl>
  );
};

export default FeaturesSubs;